/*------------------------------------
  Typography
------------------------------------*/

.text-inherit {
  color: inherit;
}

.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing;
}

.text-lh-sm {
  line-height: $text-lh-sm;
}

.text-lh-lg {
  line-height: $text-lh-lg;
}

.font-size-1 {
  font-size: $font-size-1;
}

.font-size-2 {
  font-size: $font-size-2;
}

.font-size-3 {
  font-size: $font-size-3;
}

.font-size-4 {
  font-size: $font-size-4;
}

.font-size-5 {
  font-size: $font-size-5;
}