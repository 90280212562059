/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/

.fancybox-theme .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}

.fancybox-theme .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}

.fancybox-theme .fancybox-content {
  background-color: transparent;
}

.fancybox-theme .fancybox-bg {
  background-color: $fancybox-theme-bg-color;
}

.fancybox-theme .fancybox-button svg {
  margin-bottom: 0;
}

.fancybox-theme .fancybox-progress {
  background-color: $fancybox-theme-progress-bg-color;
}

.blur-30 {
  filter: $fancybox-blur-30;
}