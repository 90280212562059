/*------------------------------------
  Select2
------------------------------------*/

.select2-custom {
  position: relative;

  .select2-custom-hide {
    display: none;
  }
}

// Right alignment
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .select2-custom#{$infix}-right .select2-container--open {
      right: 0 !important;
      left: auto !important;
    }
  }
}

.select2-dropdown {
  border-color: $select2-dropdown-border-color;
}

.select2-container--open .select2-dropdown--below {
  @include border-radius($custom-select-border-radius);
  border-top: $custom-select-border-width solid $custom-select-border-color;
  margin-top: $select2-dropdown-below-margin-y;
}

.select2-container--default {
  &.select2-container--open.select2-container--below .select2-selection--single,
  &.select2-container--open.select2-container--below .select2-selection--multiple {
    @include border-radius($custom-select-border-radius);
  }

  &.select2-container--focus .select2-selection--multiple {
    border-color: $select2-selection-multiple-focus-border-color;
  }

  .custom-select.select2-selection--multiple,
  .form-control.select2-selection--multiple {
    height: auto;
    min-height: $input-height;
  }

  .custom-select-sm.select2-selection--multiple,
  .form-control-sm.select2-selection--multiple {
    min-height: $input-height-sm;
  }

  .form-control.select2-selection--multiple .select2-selection__choice {
    margin-top: $select2-form-control-selection-choice-margin-y;
  }

  .custom-select-sm.select2-selection--multiple .select2-selection__choice,
  .form-control-sm.select2-selection--multiple .select2-selection__choice {
    margin-top: $select2-form-control-sm-selection-choice-margin-y;
  }

  .form-control.select2-selection--multiple .select2-search--inline {
    margin-top: $select2-form-control-selection-search-inline-margin-y;
  }

  .form-control-lg.select2-selection--multiple .select2-selection__choice {
    margin-top: $select2-form-control-lg-selection-choice-margin-y;
  }

  .select2-selection--multiple {
    border-color: $select2-selection-multiple-border-color;
    padding: $select2-selection-multiple-padding-y $select2-selection-multiple-padding-x;
  }

  .select2-selection--multiple .select2-selection__choice {
    position: relative;
    background-color: $select2-selection-choice-bg-color;
    border-color: $select2-selection-choice-border-color;
    padding-right: $select2-selection-choice-padding-x;
  }

  .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    right: $select2-selection-choice-remove-right-offset;
    color: $select2-selection-choice-remove-color;
  }

  .select2-search__field {
    width: 100% !important;
  }

  .select2-search__field::placeholder {
    color: $select2-search-field-input-placeholder-color;
  }

  .select2-search--dropdown .select2-search__field {
    border-color: $input-border-color;
    background-color: $select2-search-field-bg-color;
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include border-radius($input-border-radius);

    &::placeholder {
      color: $input-placeholder-color;
    }
  }

  .select2-search--dropdown .select2-search__field:focus {
    border-color: $select2-search-field-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .select2-selection.active .select2-selection__placeholder {
    color: $select2-selection-active-color;
  }

  .custom-select .select2-search--inline .select2-search__field,
  .select2-selection--multiple .select2-selection__choice {
    margin-top: $select2-search-field-margin-y;
  }

  .custom-select-sm .select2-search--inline .select2-search__field,
  .form-control-sm .select2-search--inline .select2-search__field {
    margin-top: $select2-search-field-sm-margin-y;
  }

  .select2-selection--multiple .select2-selection__rendered {
    padding-right: $input-padding-x;
    padding-left: $input-padding-x;
  }

  .select2-results__option {
    padding: $select2-results-option-padding;

    &[aria-selected=true] {
      color: $select2-results-option-true-color;
      background-color: $select2-results-option-true-bg-color;
    }

    &:first-child {
      &, &:hover {
        @include border-top-radius($custom-select-border-radius);
      }
    }

    &:last-child {
      &, &:hover {
        @include border-bottom-radius($custom-select-border-radius);
      }
    }
  }

  .select2-results__option {
    position: relative;
  }

  .select2-results__option[aria-selected=true]::after {
    position: absolute;
    top: 50%;
    right: $select2-results-option-selected-pseudo-right-offset;
    width: $select2-results-option-selected-pseudo-width;
    height: $select2-results-option-selected-pseudo-height;
    background: $select2-results-option-selected-pseudo-bg;
    content: "";
    transform: translateY(-50%);
  }

  .select2-results__option--highlighted[aria-selected]:not([aria-selected=true]) {
    color: $select2-results-option-selected-color;
    background-color: $select2-results-option-selected-bg-color;
  }

  .select2-search--dropdown .select2-search__field {
    border-color: $select2-search-field-border-color;
  }
}

.select2-selection__placeholder {
  color: $select2-selection__placeholder-color;
}

.select2-selection--multiple .select2-selection__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: $select2-selection-placeholder-padding-x;
  transform: translateY(-50%);
}