/*------------------------------------
  Slick Arrows
------------------------------------*/

.slick-arrow {
	z-index: 1;
	line-height: 0;
	display: inline-block;
	color: $slick-arrow-color;
	background-color: $slick-arrow-bg-color;
	width: $slick-arrow-width;
	height: $slick-arrow-width;
	font-size: $slick-arrow-font-size;
	cursor: pointer;
	transition: $slick-arrow-transition;

	&:not(.slick-disabled):hover {
		color: $slick-arrow-hover-color;
		background-color: $slick-arrow-hover-bg-color;
	}

	&::before {
		display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 100%;
	  height: 100%;
	}

	&-left {
		left: 0;
	}

	&-right {
		right: 0;
	}

	&.slick-disabled {
		color: $slick-arrow-disabled-color;
	}
}

/* Primary */
.slick-arrow-primary {
	color: $slick-arrow-primary-color;
	background-color: $slick-arrow-primary-bg-color;
}

/* Soft White */
.slick-arrow-soft-white {
	color: $slick-arrow-soft-white-color;
	background-color: $slick-arrow-soft-white-bg-color;

	&:hover {
		color: $slick-arrow-soft-white-hover-color;
		background-color: $slick-arrow-soft-white-hover-bg-color;
	}
}

/* Offset */
@include media-breakpoint-up(lg) {
	.slick-arrow-offset {
		&.slick-arrow-left {
			left: -$slick-arrow-width;
		}

		&.slick-arrow-right {
			right: -$slick-arrow-width;
		}
	}
}

/* Vertical Center Alignment */
.slick-arrow-centered-y {
	@include content-centered(false, true);
}