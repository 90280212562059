// Bootstrap setup
@import '../node_modules/bootstrap/scss/functions';

// Front setup
@import 'themes/default';

@import 'user-variables';

@import 'front/variables';

// Bootstrap core
@import '../node_modules/bootstrap/scss/bootstrap';

/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v3.3.0
  * Copyright 2018 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

@import 'front/front';

// Custom core
@import 'user';

// Custom core
@import 'search';