/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

.dropdown-menu {
  border-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.navbar-brand img {
  width: auto !important;
  height: 30px !important;
  margin-bottom: 9px;
}

.btn-outline-warning,
.btn-outline-secondary,
.btn-outline-primary {
  border-width: 2px;
}

.highlight-icon {
  --fa-secondary-color: #102F63;
  --fa-primary-color: #F7A10A;
  --fa-secondary-opacity: 0.9;
}

.hs-mega-menu {
  .highlight-icon {
    --fa-secondary-color: #102F63;
    --fa-primary-color: #F7A10A;
    --fa-secondary-opacity: 0.9;
  }

  .navbar-promo-icon {
    font-size: 2.4rem;
  }

  .navbar-promo-title {
    font-weight: 300;
  }

  .navbar-promo-text {
    font-size: 0.9rem;
    line-height: 1.3;
  }

  .disabled {
    .highlight-icon {
      --fa-secondary-color: #102F63;
      --fa-primary-color: #F7A10A;
      --fa-secondary-opacity: 0.7;
      --fa-primary-opacity: 0.7;
    }

    .navbar-promo-title,
    .navbar-promo-text {
      color: #555;
    }
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border-color: $light !important;
  border-top-width: 3px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.w-onesixth {
  width: 16.6666666667% !important;
}

.nav-segment.nav-pills {
  border-radius: 0.25rem !important;

  & .nav-link {
    border-radius: 0.25rem !important;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-nav {
    .navbar-nav-item {
      .nav-link {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;

        &.active {
          text-decoration: underline;
          text-decoration-thickness: 3px;
          text-underline-offset: 7px;
          text-decoration-color: $yellow;
        }
      }
    }

    .hs-mega-menu-opened {
      .hs-mega-menu-invoker {
        background-color: $light;
      }
    }
  }

  .header {
    .header-hide-content {
      height: 37px !important;
    }

    #logoAndNav {
      height: 75px !important;
    }

    .navbar-promo-icon {
      font-size: 2.4rem;
    }

    .hs-mega-menu {
      &.hs-mega-menu-overlay {
        position: fixed;
        left: 0;
        top: 112px;
        right: 0;
        bottom: 0;
        background: rgba(33, 50, 91, 0.7) none repeat scroll 0% 0%;
      }
    }

    &.scrolled {
      .hs-mega-menu {
        &.hs-mega-menu-overlay {
          top: 75px;
        }
      }
    }

    &:not(.scrolled) {
      .navbar-nav {
        .hs-mega-menu-invoker {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
      }
    }
  }
}

header,
.header-section {
  /* background-color: #e6e9ef; */
  background-color: #fff !important;
  border-bottom-color: #f0f0f0 !important;
}

.card {
  .card-header {

    h4,
    .h4 {
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1;
    }

    .badge {
      font-size: 0.7rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  h1.display-4 {
    font-size: 2rem !important;
  }
}

@include media-breakpoint-up(md) {
  .dictionary-content {
    .alphabet-columns {
      -webkit-columns: auto 2;
      /* Chrome, Safari, Opera */
      -moz-columns: auto 2;
      /* Firefox */
      columns: auto 2;
      margin-top: 30px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dictionary-content {
    .alphabet-columns {
      -webkit-columns: auto 3;
      /* Chrome, Safari, Opera */
      -moz-columns: auto 3;
      /* Firefox */
      columns: auto 3;
      margin-top: 30px;
    }
  }
}

.dictionary-content {
  .alphabet-columns h4 {
    font-weight: 700;
    margin-bottom: 0 !important;
  }



  .letter-box {
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari */
    page-break-inside: avoid;

  }

  ul.alphabet-list {
    margin: 5px 50px 5px 0;
    padding: 0;
  }

  .alphabet-list li {
    list-style-type: none;
  }

  .alphabet-list a {
    text-decoration: none;
    font-size: 1.3rem;
    color: #038889;
  }
}

#blog-post-content,
#tutorial-article-content {
  h1 {
    font-weight: 300;
  }

  img:not(.img-no-shadow) {
    max-width: 100% !important;
    height: auto !important;
    box-shadow: $box-shadow-lg;
    border-radius: $border-radius;
  }

  ol {
    li {
      margin-bottom: 1rem;
    }
  }

  table {
    margin-bottom: 1rem;

    td {
      padding: 0.5rem;
      font-size: 90%;
    }
  }

  blockquote {
    margin: 1rem 0 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
    font-style: italic !important;
    padding: 1rem !important;
  }

  table {
    tr {

      td,
      th {
        border: 1px solid #ccc !important;
      }
    }
  }
}

/**
 * Auto complete
 */
/* Autocomplete */

.aa-Panel {
  position: fixed;
}

/* InstantSearch */

.ais-Hits {
  margin-top: 1rem;
}

.ais-Hits-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.ais-Hits-item {
  padding: 1rem !important;
}

.hit {
  align-items: center;
  display: grid;
  gap: 1rem;
}

.hit h1 {
  font-size: 1rem;
}

.hit p {
  font-size: 0.8rem;
  opacity: 0.8;
}

.hit-image {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
}

.hit-image img {
  max-height: 100%;
}

.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent>div:first-of-type .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-HierarchicalMenu-item--selected:not(.ais-HierarchicalMenu-item--parent) .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-Pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

#overlay-outer {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  cursor: pointer;

  #overlay-heading {
    height: 25px;
    padding: 0 10px;

    h4 {
      line-height: 25px;
      padding: 0;
      margin: 0;
      font-size: 20px;
    }

    .fad {
      line-height: 25px;
      font-size: 20px;
    }
  }

  #overlay-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    iframe {
      width: 800px;
      max-width: 90vw;
      height: 80vh;
      max-height: 800px;
      border: 0;
    }
  }
}

.legal-content {
  table {
    thead {
      tr {
        border-bottom: 1px solid #d0d0d0;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e0e0e0;
      }
    }

    th,
    td {
      font-size: 80%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.display-4 {
  font-weight: 400;
  font-size: 3rem;
}

.nav-pills {
  .nav-item {
    .active {
      background-color: $warning;
      color: #1e2022 !important;
    }
  }
}

.sector-switch {
  .nav-pills {
    background-color: #fff;
    
    .nav-item {
      .active {
        background-color: $purple-light;
        color: white !important;
      }
    }
  }
}

.show-more-expander {
  cursor: pointer;
  font-weight: 600 !important;
  color: $primary !important;
}

.get-started-mobile {
  .btn {
    font-size: 0.8rem;

    h2 {
      font-size: calc(1.1rem + 0.75vw);
    }
  }
}

.woot--bubble-holder {
  position: absolute;
}

li>statusiq-status-widget {
  margin-left: -4px;
}

body>statusiq-status-widget {
  position: absolute;
  z-index: 10000;
}