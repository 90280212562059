/*------------------------------------
  Dropdown
------------------------------------*/

/* Menu */
.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-header {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

/* Nav Link */
.dropdown-nav-link {
  color: $dropdown-link-active-color;
  font-size: $dropdown-link-font-size;
}

/* Item */
.dropdown-item {
	font-size: $dropdown-item-font-size;

  &:active {
    color: $dropdown-link-color;
    background-color: $dropdown-link-hover-bg;
  }

  &.active:not(:focus):not(:active) {
    color: $dropdown-link-active-color;
    background-color: $dropdown-link-active-bg;
  }

  &-icon {
    display: inline-block;
    text-align: center;
    color: $dropdown-icon-color;
    opacity: $dropdown-icon-opacity;
    font-size: $dropdown-icon-font-size;
    min-width: $dropdown-icon-width;
    max-width: $dropdown-icon-width;
    margin-right: $dropdown-icon-margin-x;
  }
}

/* Toggle */
.dropdown-toggle {
  display: flex;
  align-items: center;

  &::after {
    display: inline-block;
    width: $dropdown-toggle-pseudo-width;
    height: $dropdown-toggle-pseudo-height;
    background: $dropdown-toggle-pseudo-bg;
    content: "";
  }
  
  &:not(.dropdown-toggle-empty)::after {
    margin-left: $dropdown-toggle-pseudo-margin-x;
  }

  &-collapse {
    &::after {
      transition: $dropdown-toggle-arrow-transition;
    }

    &[aria-expanded="true"] {
      &::after {
        transform: $dropdown-toggle-arrow-collapse-rotation;
      }
    }
  }
}

/* Card */
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
}

/* Dropdown Positions */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-top {
      top: 0;
    }

    .dropdown-menu#{$infix}-bottom {
      top: auto;
      bottom: 0;
    }
  }
}