/*------------------------------------
  Custom Buttons
------------------------------------*/

.btn.dropdown-toggle {
  display: inline-flex;
  align-items: center;
}

// White Button
.btn-white {
  font-weight: $btn-white-font-weight;
  background-color: $btn-white-bg-color;
  border-color: $btn-white-border-color;

  &.dropdown-toggle::after {
    background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$secondary}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
  }

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: $btn-white-hover-color;
    box-shadow: $btn-white-box-shadow-hover;

    &.dropdown-toggle::after {
      background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$primary}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
    }
  }

  &.disabled,
  &:disabled {
    color: $btn-white-disabled-color;
    background-color: $btn-white-bg-disabled-color;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &.dropdown-toggle::after {
      background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{(color-yiq($value))}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    &.dropdown-toggle::after {
      background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$value}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
    }

    &:hover,
    &:active,
    &.active {
      &.dropdown-toggle::after {
        background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{(color-yiq($value))}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
      }
    }
  }
}

@each $color, $value in $theme-colors {
	/* #{$color} button :hover, :focus, :active effects */
  .btn-#{$color} {
    &[href],
    &[type] {
      &:hover,
      &:focus,
      &:active {
        box-shadow: $btn-box-shadow-value rgba($value, 0.35);
      }
    }

    &:not(label.btn),
    &:not([href]) {
      &,
      &:not([href]):not(:disabled):not(.disabled) {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .btn-#{$color}:hover {
    background-color: $value;
    border-color: $value;
  }
}