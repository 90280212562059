/*------------------------------------
  Video Background
------------------------------------*/

/*------------------------------------
  Video Background
------------------------------------*/

.hs-video-bg-video video {
  object-fit: cover;
}