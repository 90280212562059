/*------------------------------------
  Checkbox Icon
------------------------------------*/

.checkbox-icon-label::after {
  position: absolute;
  top: $checkbox-icon-label-psuedo-top-offset;
  right: $checkbox-icon-label-psuedo-right-offset;
  width: $checkbox-icon-label-psuedo-width;
  height: $checkbox-icon-label-psuedo-height;
  color: $checkbox-icon-label-psuedo-color;
  background: $checkbox-icon-label-psuedo-bg;
  content: "";
}

// Checked
.checkbox-icon-input:checked ~ .checkbox-icon-label::after {
  background: $checkbox-icon-label-active-psuedo-bg;
}