// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;

$root-path: '/assets/';

// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg

$cyan: #038889;
$teal: #038889;
$yellow: #F7A10A;
$purple: #19062E;
$purple-light: #4a3661;
$info: #FFF2C9;
$blue: #102F63;
$light: #f6f6f6;
$white: #fff;

// Theme Colors
$primary:                                                   $blue;
$secondary:                                                 $cyan;
$success:                                                   $teal;
$info:                                                      $info;
$warning:                                                   $yellow;
$danger:                                                    $red;
$dark:                                                      $purple;

$header-navbar-brand-width:                                 12rem !default;
$header-navbar-brand-mobile-width:                          11rem !default;
$headings-font-weight: 300;
$border-radius: .35rem;
$border-radius-sm: .35rem;
$border-radius-md: .35rem;
$border-radius-lg: .35rem;

/*
$spacer: 1.3rem;

$container-max-widths: (
  xl: 1400px
);

$font-size-base: 1.3rem;
*/